<template>
</template>

<script>
export default {
  created() {
    this.$router.replace({ name: 'index' })
  }
}
</script>
